// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-doplnky-js": () => import("./../../../src/pages/doplnky.js" /* webpackChunkName: "component---src-pages-doplnky-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-oleje-js": () => import("./../../../src/pages/oleje.js" /* webpackChunkName: "component---src-pages-oleje-js" */),
  "component---src-pages-produkt-js": () => import("./../../../src/pages/produkt.js" /* webpackChunkName: "component---src-pages-produkt-js" */)
}

